import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }

  interface PaletteColor {
    white?: string;
    black?: string;
  }

  interface SimplePaletteColorOptions {
    white?: string;
    black?: string;
  }
}

let theme = createTheme({
  palette: {
    primary: {
      main: "#2f50ff",
      dark: "#233FD3",
      light: "#DDE6FF",
    },
    secondary: {
      main: "#FE7A7A",
      dark: "#E15454",
      light: "#FFE2E4",
    },
    success: {
      main: "#12B484",
      dark: "#0C936B",
      light: "#C6F2E5",
    },
    warning: {
      main: "#FFBE4C",
      dark: "#E7A33B",
      light: "#FDEFCA",
    },
    neutral: {
      main: "#9794AB",
      dark: "#1D1D20",
      light: "#F9F9F9",
      white: "#FFFFFF",
      black: "#000000",
    },
  },
  breakpoints: {
    values: {
      xs: 490 + 1,
      sm: 768 + 1,
      md: 1024 + 1,
      lg: 1366 + 1,
      xl: 1536 + 1,
    },
  },
  typography: {
    button: {
      textTransform: "none",
      fontFamily: "inherit",
      fontSize: 18,
      "@media (max-width:1024px)": {
        fontSize: "17px",
      },

      "@media (max-width:490px)": {
        fontSize: "16px",
      },
    },
    h1: {
      fontSize: "66px",
      fontWeight: "700",
      lineHeight: "66px",
      letterSpacing: "0.27px",
      width: "100%",
      fontFamily: "inherit",
      "@media (max-width:1024px)": {
        fontSize: "46px",
        lineHeight: "46px",
      },
      "@media (max-width:768px)": {
        fontSize: "40px",
        lineHeight: "40px",
        maxWidth: "80% !important",
      },
      "@media (max-width:490px)": {
        fontSize: "30px",
        lineHeight: "33px",
        maxWidth: "80% !important",
      },
      "@media (max-width:450px)": {
        maxWidth: "100% !important",
      },
    },
    h2: {
      fontSize: "56px",
      fontWeight: "700",
      lineHeight: "60px",
      letterSpacing: "0.31px",
      width: "100%",
      fontFamily: "inherit",
      "@media (max-width:1024px)": {
        fontSize: "36px",
        lineHeight: "36px",
      },
      "@media (max-width:768px)": {
        fontSize: "32px",
        lineHeight: "35px",
      },
      "@media (max-width:490px)": {
        fontSize: "27px",
        lineHeight: "30px",
      },
    },
    h3: {
      fontSize: "48px",
      fontWeight: "700",
      lineHeight: "54px",
      letterSpacing: "0.27px",
      width: "100%",
      fontFamily: "inherit",
      "@media (max-width:1024px)": {
        fontSize: "40px",
        lineHeight: "48px",
      },
      "@media (max-width:768px)": {
        fontSize: "28px",
        lineHeight: "32px",
      },
      "@media (max-width:490px)": {
        fontSize: "24px",
        lineHeight: "27px",
      },
    },
    h4: {
      fontFamily: "Open Sans",
      fontSize: "36px",
      fontWeight: "600",
      lineHeight: "42px",
      letterSpacing: "0.2px",
      width: "100%",
      marginBottom: "16px",
      "@media (max-width:1024px)": {
        fontSize: "26px",
        lineHeight: "29px",
      },
      "@media (max-width:768px)": {
        fontSize: "24px",
        lineHeight: "29px",
      },
      "@media (max-width:490px)": {
        fontSize: "20px",
        lineHeight: "25px",
      },
    },
    h5: {
      fontFamily: "Open Sans",
      fontSize: "22px",
      fontWeight: "500",
      lineHeight: "28px",
      letterSpacing: "0.12px",
      width: "100%",
      marginBottom: "16px",
      "@media (max-width:1024px)": {
        fontSize: "20px",
        lineHeight: "24px",
      },
      "@media (max-width:768px)": {
        fontSize: "18px",
        lineHeight: "22px",
      },
      "@media (max-width:490px)": {
        fontSize: "16px",
        lineHeight: "20px",
      },
    },
    body1: {
      fontFamily: "Open Sans",
      fontSize: "18px",
      fontWeight: "400",
      letterSpacing: "0.1px",
      lineHeight: "24px",
      width: "100%",
      marginBottom: "48px",
      "@media (max-width:1024px)": {
        fontSize: "17px",
        lineHeight: "22px",
      },
      "@media (max-width:490px)": {
        fontSize: "16px",
        lineHeight: "21px",
      },
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          height: "56px",
          backgroundColor: "#ffffff",
          width: "47%",
          marginBottom: "35px",
          "&.full-width": {
            width: "100%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
        },
      },
    },
    // TextField and Input styling
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          border: "1px solid #E4E3E9",
          borderRadius: "12px",
          "&.Mui-focused": {
            backgroundColor: "#ffffff",
          },
          "&:hover": {
            backgroundColor: "#ffffff",
          },
          "&::before": {
            border: "none !important",
            "&.Mui-focused": {
              border: "none",
            },
          },
          "&:after": {
            border: "none",
          },
          "&.MuiInputBase-multiline": {
            marginBottom: "0",
          },
        },
        input: {
          paddingTop: "22px",
          paddingBottom: "7.5px",
          fontFamily: "Open Sans",
          fontSize: "16px",
          lineHieght: "22px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontFamily: "Open Sans",
          color: "#777e91",
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, 4px) scale(0.75)",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    // Menu Styling
    MuiMenu: {
      styleOverrides: {
        paper: {
          height: "auto",
          width: "auto",
          maxWidth: "100vw",
          borderRadius: "13px",
          padding: "10px 0",
          backgroundColor: "#FFFFFF",
          boxShadow: "0 4px 20px 0 rgba(112,144,176,0.15)",
          fontFamily: "Open Sans",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          marginBottom: "0",
          "&.page-choice": {
            flex: "1",
            flexDirection: "column",
            backgroundColor: "rgba(255,255,255,0.8)",
            padding: "0 32px",
            borderRight: "1px solid #e4e3e9",
            alignItems: "flex-start",
            marginBottom: "0",
            "&:last-child": {
              borderRight: "none",
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#ffffff",
            },
            "&:hover": {
              backgroundColor: "#ffffff",
              "&.page-choice": {
                color: "#2f50ff",
              },
            },
            "@media (max-width: 900px)": {
              padding: "0 26px",
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: "85%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "& .MuiButton-endIcon": {
            transition: "0.35s ease",
          },
          "&:hover": {
            backgroundColor: "transparent",
            "& .MuiButton-endIcon": {
              transform: "translateX(10px)",
            },
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          lineHeight: "27px",
          letterSpacing: "0.1px",
          fontWeight: "500",
          padding: "12px 39px",
          fontFamily: "pragmatica-extended",
          "&.sub-choice": {
            padding: "21px 32px",
            borderRadius: "32px",
            width: "85%",
            margin: "0 0 12px 33px",
          },
          "&:active": {
            color: "inherit",
          },
        },
      },
    },
  },
});

export default theme;
