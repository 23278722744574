import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useClient } from "./context/ClientContext";
import LayoutStyles from "./App.styles";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Profile from "./pages/Profile";
import NotAvailable from "./pages/NotAvailable";

const Layout = () => {
  const { dataLoading } = useClient();

  return (
    <LayoutStyles.Container>
      <Header />
      <BrowserRouter basename="/profile">
        <Routes>
          <Route path="/" element={dataLoading ? <LayoutStyles.BlankPage></LayoutStyles.BlankPage> : <Profile />} />
          <Route path="/empty" element={<NotAvailable />} />
        </Routes>
      </BrowserRouter>
      <Footer />
      
    </LayoutStyles.Container>
  );
};

export default Layout;
