import React from "react";
import Layout from "./MainLayout";
import ClientProvider from "./context/ClientContext";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import Meta from "./Meta";

function App() {
  return (
    <ClientProvider>
      <Meta />
      <ThemeProvider theme={theme}>
        <Layout />
      </ThemeProvider>
    </ClientProvider>
  );
}

export default App;
