import React, { useEffect, useRef, useState } from "react";
import { useClient } from "../../context/ClientContext";
import LayoutStyles from "../../App.styles";
import QRCodeStyling from "qr-code-styling";
import adtLogo from "../../assets/images/adt-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownToLine,
  faLinkSimple,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import AppleWallet from "../../assets/images/apple_wallet.svg";
import GooglePay from "../../assets/images/google_pay.svg";
import { createWalletPass } from "../../actions";
import { AnalyticsInputType } from "../../types/AnalyticsTypes";

type Props = {
  pfp: string;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  urlLink: string;
  name: string;
  location: string;
  title: string;
};

const QRModal: React.FC<Props> = ({
  pfp,
  name,
  location,
  title,
  closeModal,
  urlLink,
}) => {
  const { QrModal } = LayoutStyles;
  const ref = useRef<HTMLDivElement>(null);
  const [copied, setCopied] = useState(false);

  const isAppleDevice = /Mac|iPhone|iPad|iPod/.test(navigator.userAgent);
  const { profileData, handleAppAnalytics } = useClient();
  const centerImage = pfp.length > 0 ? pfp : adtLogo;

  const [qrCode] = useState(
    new QRCodeStyling({
      width: 232,
      height: 232,
      data: urlLink,
      image: centerImage,
      qrOptions: {
        typeNumber: 0,
        mode: "Byte",
        errorCorrectionLevel: "Q",
      },
      dotsOptions: {
        color: "black",
        type: "dots",
      },
      cornersDotOptions: {
        type: "dot",
      },
      cornersSquareOptions: {
        type: "extra-rounded",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 10,
        imageSize: 0.4,
        hideBackgroundDots: true,
      },
    })
  );

  useEffect(() => {
    if (qrCode) {
      const canvasContainer = document.getElementById("qr-container");
      const canvasCreated =
        ref.current && ref.current?.getElementsByTagName("canvas").length > 0;
      console.log("qrCode", qrCode);
      if (
        !canvasCreated &&
        canvasContainer &&
        canvasContainer.getElementsByTagName("canvas").length === 0
      )
        qrCode.append(canvasContainer);
    }
  }, [qrCode]);

  const handleWalletSaveClick = async (walletType: "ios" | "android") => {
    const walletPacket = {
      name: profileData.name,
      job_title: profileData.job_title,
      location: profileData.location,
      phone_number: profileData.phone,
      email: profileData.email,
      qrcode: window.location.href,
      type: walletType,
      publicID: profileData.publicID,
    };
    console.log(walletPacket);
    createWalletPass(walletPacket)
      .then(async (res: Blob | { result: string; url: string }) => {
        if (walletType === "ios") {
          await triggerCommunicationAnalytics(walletType + "-wallet-save");
          const url = window.URL.createObjectURL(res as Blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = "contactcard.pkpass";

          // Append the anchor tag and trigger the download
          document.body.appendChild(downloadLink);
          downloadLink.click();

          // Cleanup the DOM
          document.body.removeChild(downloadLink);
          console.log("wallet res", res);
        } else if (typeof res === "object") {
          let obj = res as { result: string; url: string };
          if (obj.result === "SUCCESS") {
            await triggerCommunicationAnalytics(walletType + "-wallet-save");
            window.open(obj.url as any, "_blank");
          }
        }
      })
      .catch((err: any) => {
        console.log("wallet error", err);
      });
  };

  const triggerCommunicationAnalytics = async (event_action: string) => {
    // eventAction?.preventDefault();
    const event: AnalyticsInputType = {
      hotspot_id: profileData.publicID,
      content_type_id: "",
      event_category: "virtual-wallet-save",
      event_action: event_action,
      label: "smart-profile",
      string_value: "",
      numeric_value: 0,
      completion_value: 0,
      duration: 0,
      spend_value: 0,
      name: profileData.email,
    };
    console.log(event);
    await handleAppAnalytics(event);
  };

  const handleCopyclick = () => {
    let copyTimer;
    clearTimeout(copyTimer);
    navigator.clipboard.writeText(urlLink);
    setCopied(true);

    copyTimer = setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const onDownloadClick = async () => {
    // qrCode.getRawData("png").then((blob) => {
    //   if (!blob) return;
    //   const file = new File([blob], "qr-code.png", { type: blob.type });
    //   console.log("file", file);
    if (navigator.share) {
      const originalCanvas = document.getElementsByTagName("canvas")[0];
      const newImage = new Image();
      newImage.src = centerImage;
      newImage.crossOrigin = "anonymous";
      newImage.width = 50;
      newImage.height = 50;
      newImage.onload = () => {
        console.log("image", newImage);
        const destinationCanvas = document.createElement("canvas");
        destinationCanvas.width = originalCanvas.width;
        destinationCanvas.height = originalCanvas.height;
        const destCtx = destinationCanvas.getContext("2d");
        destCtx?.drawImage(originalCanvas, 0, 0);
        const imageX = (destinationCanvas.width - newImage.width) / 2;
        const imageY = (destinationCanvas.height - newImage.height) / 2;
        destCtx?.drawImage(newImage, imageX, imageY, 50, 50);

        destinationCanvas.toBlob((blob) => {
          console.log("here");
          if (!blob) return;
          let file = new File([blob], "qr-code.png");
          if (navigator.share) {
            navigator
              .share({
                title: "Shared Image",
                text: "Check out this image",
                files: [file],
              })
              .then(() => console.log("Shared successfully"))
              .catch((error) => console.log("Error sharing:", error));
          } else return;
        });
      };
    } else {
      console.log("here");
      qrCode.download({
        extension: "png",
      });
    }
    // });
  };

  return (
    <QrModal.Backdrop>
      {/* This field is hidden and gets assigned the qr code as a file in order to dynamically save and send it
        to wallet */}
      <input
        type="file"
        id="fileField"
        name="myfile"
        style={{ display: "none" }}
      ></input>
      <QrModal.Box>
        <QrModal.CloseButton onClick={() => closeModal(false)}>
          <FontAwesomeIcon size="2x" color="#fff" icon={faXmark} />
        </QrModal.CloseButton>
        <QrModal.QRContainer>
          <div id="qr-container" ref={ref} style={{ position: "relative" }}>
            <QrModal.ImageContainer pfp={centerImage} />
          </div>
          <QrModal.Name>{name}</QrModal.Name>
          <QrModal.Title>{title}</QrModal.Title>
          <QrModal.Location>{location}</QrModal.Location>
        </QrModal.QRContainer>
        <QrModal.QRButton onClick={handleCopyclick}>
          {!copied && (
            <FontAwesomeIcon
              style={{ marginRight: "12px" }}
              color="#474D5D"
              icon={faLinkSimple}
            />
          )}
          {copied ? "Copied!" : "Copy link"}
        </QrModal.QRButton>
        <QrModal.QRButton onClick={onDownloadClick}>
          <FontAwesomeIcon
            style={{ marginRight: "12px" }}
            color="#474D5D"
            icon={faDownToLine}
          />
          Save as image
        </QrModal.QRButton>
        {isAppleDevice ? (
          <img
            onClick={() => handleWalletSaveClick("ios")}
            style={{ height: "64px", width: "100%", marginTop: "15px" }}
            src={AppleWallet}
            alt="Add to Apple Wallet"
          />
        ) : (
          <img
            onClick={() => handleWalletSaveClick("android")}
            src={GooglePay}
            style={{ width: "84%", margin: "11px auto 0" }}
            alt="Add to Google Wallet"
          />
        )}
      </QrModal.Box>
    </QrModal.Backdrop>
  );
};

export default QRModal;
