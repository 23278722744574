import React, { createContext, useContext, useState, useEffect } from "react";
import io from "socket.io-client";
import {
  getClientData,
  getClientProfileAbout,
  getConfigData,
  getPlayerData,
  getPublicPromotion,
  initApiClient,
  initXrnalyticsApi,
  sendAnalytics,
  getAllProfileBanners,
} from "../actions";
import { AnalyticsInputType, SessionType } from "../types/AnalyticsTypes";
import {
  AuthContext,
  ClientAboutType,
  ClientBrandingData,
  ProfileDataType,
  PromoDataType,
} from "../types/AuthTypes";
// @ts-ignore
import crypto from "crypto-browserify";
import gradientBanners from "../assets/data/cover_banners.json";

const ClientCtx = createContext({});
const socket = io(process.env.REACT_APP_SOCKET_CONNECTION as string, {
  transports: ["websocket"],
});

const ClientProvider = ({ children }: { children: React.ReactNode }) => {
  const [configData, setConfigData] = useState<any>({});
  const [initialized, setInitialize] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [profileData, setProfileData] = useState<ProfileDataType | undefined>();
  const [promoData, setPromoData] = useState<PromoDataType | undefined>();
  const [clientAboutData, setClientAboutData] = useState<
    ClientAboutType | undefined
  >();
  const [clientBranding, setClientBranding] = useState<ClientBrandingData>();
  const [clientName, setClientName] = useState("");
  const [ipAddressHashed, setIpAddressHashed] = useState("");

  const [analyticsStartEntryID, setAnalyticsStartEntryID] = useState(0);
  const [analyticsTriggerEntryID, setAnalyticsTriggerEntryID] = useState(0);
  const [sessionID, setSessionID] = useState("");
  const [locationData, setLocationData] = useState({
    state: "",
    city: "",
    zipcode: "",
  });
  const [coverArray, setCoverArray] = useState<any[]>(gradientBanners);
  const [usesCoverImgs, setUsesCoverImgs] = useState(false);

  const generateSessionID = () => {
    let result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 7; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  // intialize the application and get the configuration data
  useEffect(() => {
    if (initialized) return;
    const initApi = initApiClient();
    const initAnalyticApi = initXrnalyticsApi();
    const getClient = getClientData();
    const clientIDEnv = process.env.REACT_APP_CLIENT_NAME;
    const getCoverBanners = getAllProfileBanners({ clientID: ""+clientIDEnv });

    Promise.all([getClient, initApi, initAnalyticApi, getCoverBanners]).then(
      (res) => {
        const { database, tenantID, branding, client } = res[0];
        const bannerRes = res[3];
        if (bannerRes) {
          setUsesCoverImgs(true);
          setCoverArray(bannerRes.data);
        }
        console.log("banners", bannerRes);
        setClientBranding(branding);
        setClientName(client);
        console.log(client);
        console.log(res);
        getConfigData({ application_id: database, tenant_id: tenantID }).then(
          (configRes: any) => {
            setConfigData(JSON.parse(configRes));
            if (res[1] === "SUCCESS" && res[2] === "SUCCESS") {
              setInitialize(true);
            }
          }
        );
      }
    );
  }, [initialized]);

  // get user's ip
  useEffect(() => {
    let viewer_id = localStorage.getItem("viewer_id");
    if (viewer_id) {
      setIpAddressHashed(viewer_id);
      let sID = generateSessionID();
      console.log(`${viewer_id}_${sID}`);
      setSessionID(`${viewer_id}_${sID}`);
    } else {
      const hashIpAddress = (ipAddress: string) => {
        const hash = crypto.createHash("sha256");
        hash.update(ipAddress);
        return hash.digest("hex");
      };
      fetch("https://api.ipify.org/?format=json")
        .then((response) => response.json())
        .then((data) => {
          let hashedIp = hashIpAddress(data.ip);
          localStorage.setItem("viewer_id", hashedIp);
          setIpAddressHashed(hashedIp);
          let sID = generateSessionID();
          console.log(`${hashedIp}_${sID}`);
          setSessionID(`${hashedIp}_${sID}`);
        })
        .catch((error) => console.error(error));
    }
  }, []);

  // Get the player data
  useEffect(() => {
    if (initialized && clientName) {
      // get the id from the url
      let id = new URLSearchParams(window.location.search).get("id") as string;
      console.log("param id", id);
      getPlayerData("publicID", id).then(
        async (res: {
          result: string;
          content: {
            isAdmin: boolean;
            customData: ProfileDataType;
            location: { city: string; state: string; zipcode: string };
            publicID: string;
            status: number;
          };
        }) => {
          console.log("important", res);
          if (res.content.status !== 1) {
            window.location.href = window.location.origin + "/profile/empty";
            setDataLoading(false);
            return;
          }
          let profileOwnerLoc = {
            city: res.content.location
              ? res.content.location.city
              : res.content.customData.location
              ? res.content.customData.location
              : "N/A",
            state: res.content.location
              ? res.content.location.state
              : res.content.customData.location
              ? res.content.customData.location
              : "N/A",
            zipcode: res.content.location
              ? res.content.location.zipcode
              : res.content.customData.location
              ? res.content.customData.location
              : "N/A",
          };
          setLocationData(profileOwnerLoc);
          if (res.result === "SUCCESS") {
            setProfileData({
              ...res.content.customData,
              publicID: res.content.publicID,
              isAdmin: res.content.isAdmin,
            });

            if (res.content?.customData?.promo_banners) {
              getPublicPromotion({
                promoID: res.content?.customData?.promo_banners[0],
              }).then(
                (getPromoRes: { result: string; data: PromoDataType }) => {
                  console.log(getPromoRes);
                  if (getPromoRes.result === "SUCCESS") {
                    setPromoData(getPromoRes.data);
                  }
                }
              );
            }

            getClientProfileAbout({ client: clientName }).then(
              (getClientAboutRes: {
                result: string;
                data: ClientAboutType;
              }) => {
                console.log("about res", getClientAboutRes);
                if (getClientAboutRes.result === "SUCCESS") {
                  setClientAboutData(getClientAboutRes.data);
                }
              }
            );
          }
        }
      );
    }
  }, [initialized, clientName]);

  useEffect(() => {
    if (profileData) setDataLoading(false);
  }, [profileData]);

  useEffect(() => {
    if (profileData && locationData) {
      const event: AnalyticsInputType = {
        hotspot_id: profileData.publicID,
        content_type_id: "",
        event_category: "session",
        event_action: "customer-session",
        label: "smart-profile",
        string_value: "",
        numeric_value: 0,
        completion_value: 0,
        duration: 0,
        spend_value: 0,
        name: profileData.email,
      };
      handleAppAnalytics(event);
      console.log("here");
    }
  }, [profileData, locationData]);

  const handleAppAnalytics = async (eventObj: AnalyticsInputType) => {
    const analyticsUserData = {
      sso_id: ipAddressHashed,
      xureal_player_id: "",
      name: "",
      session_id: sessionID,
      location: "", // need to update this to figure current player location
      division: "",
      channel: "",
      region: "",
      city: locationData.city,
      state: locationData.state,
      zipcode: locationData.zipcode,
    };

    const analyticsObj = { ...analyticsUserData, ...eventObj };
    // console.log(analyticsObj);
    try {
      let res = await sendAnalytics(analyticsObj);
      console.log(res);
      if (eventObj.event_action === "customer-session") {
        setAnalyticsStartEntryID(res.content);
        socketConnection(res.content);
      } else {
        setAnalyticsTriggerEntryID(res.content);
        // console.log(res.content);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const socketConnection = (entryID: string) => {
    socket.emit("VIEW_PROFILE", {
      entryID,
      xureal_client_name: process.env.REACT_APP_CLIENT_NAME,
      environment: process.env.REACT_APP_SOCKET_ENVIRONMENT,
    });
  };

  return (
    <ClientCtx.Provider
      value={{
        configData,
        initialized,
        profileData,
        handleAppAnalytics,
        clientBranding,
        clientName,
        ipAddressHashed,
        promoData,
        clientAboutData,
        dataLoading,
        coverArray,
        usesCoverImgs,
      }}
    >
      {children}
    </ClientCtx.Provider>
  );
};

export const useClient = () => {
  return useContext(ClientCtx) as AuthContext;
};

export default ClientProvider;
