import React from 'react';
import LayoutStyles from '../App.styles';
import { useClient } from "../context/ClientContext";

const NotAvailable: React.FC = () => {
    const { NotAvailable } = LayoutStyles;
    const { clientBranding } = useClient();
    return(
        <NotAvailable.Container>
            <img src={clientBranding?.logo ?? ""} height={43.02} alt="" />
            <NotAvailable.Headline>
                Solutions Advisor <br /> Not Available
            </NotAvailable.Headline>
            <NotAvailable.Body>
            We’re sorry, this Solutions Advisor is no longer available, but we can still help provide smart solutions that work for you. 
            <br />
            <br />
            Please call us at <a style={{textDecoration: 'none'}} href="tel:+11111111111">(111) 111-1111</a> or visit our website anytime.
            </NotAvailable.Body>
            <a style={{marginTop: '25px'}} href="https://www.adt.com">
            <LayoutStyles.Profile.Rating.SubmitButton>
                Visit ADT.com
            </LayoutStyles.Profile.Rating.SubmitButton>
            </a>
        </NotAvailable.Container>
    )
}

export default NotAvailable;