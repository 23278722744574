import styled from "styled-components";
import { faStar as faFilledStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faEmptyStar } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputBase } from "@mui/material";
import coverBanners from "./assets/data/cover_banners.json";
import ReactPlayer from "react-player";

const LayoutStyles = {
  Container: styled.div`
    height: fit-content;
    max-width: 100vw;
    background-color: #f8f8f9;
  `,
  Header: styled.div<{$center: string | null}>`
    height: 73px;
    background-color: white;
    position: sticky;
    top: 0;
    padding: 0 20px;
    display: flex;
    justify-content: ${props => props.$center === 'true' ? 'space-between' : 'center'};
    align-items: center;
    z-index: 1;
  `,
  BlankPage: styled.div`
    width: 100%;
    height: 100vh;
    background-color: #f8f8f9;
  `,
  Footer: styled.div`
    height: 145px;
    width: 100%;
    background-color: #f8f8f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px 0 0;
    color: #393939;
    font-family: Brown;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 13px;
    text-align: center;
    position: relative;
  `,
  Divider: styled.div`
    box-sizing: border-box;
    height: 1px;
    width: calc(100% - 48px);
    border: 1px solid #e4e4e4;
    margin-bottom: 40px;
  `,
  NotAvailable: {
    Container: styled.div`
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #ffffff;
      z-index: 1;
      padding: 0 40px;
    `,
    Headline: styled.h1`
      font-family: Brown;
      text-align: center;
      width: 100%;
      margin-bottom: 21px;
    `,
    Body: styled.p`
      font-family: Brown;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.17px;
      text-align: center;
    `,
  },
  Profile: {
    CoverArt: styled.div<{ $background: string }>`
      height: 100%;
      width: 100%;
      background: ${(props) =>
        props.$background ? props.$background : "lightgray"};
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-bottom: 30%;
      @media (max-width: 768px) {
        background-size: contain;
      }
    `,
    SaveContactButton: styled.p`
      color: #fff;

      text-align: center;

      box-sizing: border-box;
      text-decoration: none;

      border-radius: 20px;
      background-color: #006fb8;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      padding: 14.5px 0;
      margin: 0 0 40px;
      font-family: Brown;
      font-size: 14px;
      font-weight: bold;
      color: white;
    `,
    SeeMoreButton: styled.p`
      color: #006fb8;
      font-family: Brown;
      font-size: 14px;
      letter-spacing: 0.15px;
      line-height: 19px;
      text-align: center;
      margin: 20px auto 49px;
    `,
    LinkButton: styled.div`
      box-sizing: border-box;
      height: 59px;
      max-width: 349px;
      width: 100%;
      border: 1px solid #e4e4e4;
      border-radius: 8px;
      background-color: #ffffff;
      color: #393939;
      font-family: Brown;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
      margin: 0 auto 12px;
      padding: 0 20px;
      display: flex;
      align-items: center;
    `,
    InfoContainer: styled.div`
      border-radius: 24px;
      background-color: #fff;
      position: relative;
      /* height: 100%; */
      max-width: 1024px;
      width: 100%;
      top: -90px;
      padding: 100px 0px 0 0;
      box-sizing: border-box;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 1024px) {
        top: -18px;
      }
    `,
    SectionContainer: styled.div`
      padding: 0 24px;
      max-width: 100%;
      box-sizing: border-box;
    `,
    Pfp: styled.div<{ $pfp: string }>`
      height: 138px;
      width: 138px;
      border-radius: 50%;
      background-image: url(${(props) => props.$pfp});
      background-size: cover;
      background-position: center;
      background-color: lightgray;
      border: 3px solid #ffffff;
      position: absolute;
      left: calc(50% - 138px / 2);
      top: calc(-138px / 2);
    `,
    QrIconSection: styled.div`
      cursor: pointer;
      position: absolute;
      height: 52px;
      width: 52px;
      border: 3px solid #ffffff;
      box-sizing: border-box;
      background-color: #006fb8;
      border-radius: 50%;
      bottom: -5px;
      right: -5px;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    Name: styled.h1`
      color: #1f1f1f;
      font-family: Brown;
      font-size: 28px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
      margin: 0 0 9px;
    `,
    JobTitle: styled.p`
      color: #1f1f1f;
      font-family: Brown;
      font-size: 16px;
      letter-spacing: 0.17px;
      line-height: 19px;
      text-align: center;
      margin: 0 0 4px;
    `,
    Location: styled.p`
      color: #777e91;
      font-family: Brown;
      font-size: 16px;
      letter-spacing: 0.17px;
      line-height: 19px;
      text-align: center;
      margin: 0 0 24px;
    `,
    Feedback: styled.p`
      color: #777e91;
      font-family: Brown;
      font-size: 14px;
      letter-spacing: 0.15px;
      line-height: 19px;
      text-align: center;
      margin: 10px 0 24px;
    `,
    StatementText: styled.p`
      color: #1f1f1f;
      font-family: Brown;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.2px;
      line-height: 19px;
      text-align: center;
      margin: 0 auto 19px;
    `,
    Rating: {
      FilledStar: styled(FontAwesomeIcon).attrs({
        icon: faFilledStar,
      })`
        height: 44px;
        width: 44px;
      `,
      EmptyStar: styled(FontAwesomeIcon).attrs({
        icon: faEmptyStar,
        color: "#006fb8",
      })`
        height: 44px;
        width: 44px;
      `,
      Container: styled.div`
        display: flex;
        gap: 14px;
        justify-content: center;
        margin-bottom: 24px;
      `,
      ReviewInput: styled(InputBase)`
        && {
          color: #000000;
          font-family: Brown;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 19px;
          height: 100%;
          width: 100%;
        }
      `,
      ReviewContainer: styled.div`
        min-height: 97px;
        height: fit-content;
        box-sizing: border-box;
        max-width: 690px;
        border-radius: 26px;
        background-color: #f3f5f6;
        padding: 28px 24px 0;
        margin: 0 auto 24px;
        position: relative;
        &::before {
          content: "Describe your experience";
          position: absolute;
          top: 8px;
          color: #5e6366;
          font-family: Brown;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 19px;
          click-events: none;
        }
      `,
      SubmitButton: styled.div`
        padding: 15px 54px;
        margin: 0 auto 40px;
        border-radius: 24px;
        width: fit-content;
        background-color: #006fb8;
        color: #ffffff;
        font-family: Brown;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.15px;
        line-height: 19px;
        text-align: center;
      `,
    },

    Section: {
      Title: styled.p`
        color: #191c24;
        font-family: Brown;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1.31px;
        line-height: 19px;
        text-align: center;
        margin: 0 0 8px;
      `,
      Body: styled.p`
        color: #3e3e3e;
        font-family: Brown;
        font-size: 16px;
        letter-spacing: 0.17px;
        line-height: 20px;
        text-align: center;
        margin: 0 auto 64px;
        max-width: 690px;
        padding: 0 24px;
      `,
    },
    Tag: styled.div`
      height: 34px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      border-radius: 17px;
      background-color: #e1e0e7;

      color: #565656;
      font-family: Brown;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    `,
    RatingTag: styled.div<{ $selected?: boolean }>`
      height: 34px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      border-radius: 17px;
      border: ${({ $selected }) => ($selected ? "none" : "1px solid #e1e0e7")};
      box-sizing: border-box;
      background-color: ${({ $selected }) => ($selected ? "#006FB8" : "#FFF")};
      color: ${({ $selected }) => ($selected ? "#FFF" : "#565656")};
      font-family: Brown;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    `,
    TagsContainer: styled.div`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;
      margin: 0 auto 40px;
      max-width: 690px;
    `,

    Contact: {
      Buttons: styled.a`
        box-sizing: border-box;
        text-decoration: none;
        height: 72px;
        width: 114px;
        border-radius: 20px;
        background-color: #006fb8;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px 0 0;
        margin: 0;
        font-family: Brown;
        font-size: 14px;
        font-weight: bold;
        color: white;
      `,
      ButtonIcon: styled(FontAwesomeIcon).attrs({ color: "white" })`
        width: 24px;
        height: 24px;
        margin-bottom: 7.66px;
      `,
      Container: styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto 19px;
        padding: 12px 0 0;
        max-width: 366px;
        max-height: 72px;
        gap: 12px;
        box-sizing: border-box;
      `,
    },
    AdBanner: styled.div`
      background-color: lightgray;
      height: 159.07px;
      margin: 0 auto 49px;
      position: relative;
      max-width: 490px;
    `,
    PromoText: {
      Container: styled.div`
        background-color: #006fb8;
        padding: 40px 28px 24px;
        width: 100%;
        box-sizing: border-box;
        font-family: Brown;
        width: 100%;
      `,
      Header: styled.div`
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0.08px;
        line-height: 33px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 22px;
      `,
      Subtext: styled.div`
        font-size: 17px;
        letter-spacing: 0.04px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 40px;
      `,
      Disclaimer: styled.div`
        font-size: 12px;
        letter-spacing: 0.03px;
        line-height: 13px;
        text-align: center;
        color: #ffffff;
      `,
    },
    PromoImage: styled.img`
      width: 100%;
    `,
    ClientVideo: styled(ReactPlayer).attrs({ width: "100%" })`
      max-width: 100%;
    `,
    PlayerWrapper: styled.div`
      position: relative;
      padding-top: 56.25%;
      width: 100%;
      margin-bottom: 60px;
    `,
  },
  QrModal: {
    Backdrop: styled.div`
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #006fb8;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    `,
    Box: styled.div`
      width: 322px;
      max-width: 100%;
      display: flex;
      flex-direction: column;
    `,
    CloseButton: styled.button`
      border: none;
      box-shadow: none;
      position: absolute;
      background-color: transparent;
      top: 8px;
      right: 2px;
    `,
    QRContainer: styled.div`
      width: 100%;
      border-radius: 20px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 25px 0;
    `,
    QRButton: styled.button`
      width: 84%;
      height: 64px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border: none;
      box-shadow: none;
      margin: 15px auto 0 auto;
      font-family: Brown;
      font-weight: 600;
      font-size: 18px;
    `,
    ImageContainer: styled.div<{ pfp: string }>`
      background-image: url(${(props) => props.pfp});
      background-size: cover;
      backgroun-repeat: no-repeat;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      position: absolute;
      top: calc(50% - 30px);
      left: calc(50% - 30px);
    `,
    Name: styled.p`
      font-size: 28px;
      font-family: Brown;
      font-weight: 600;
      margin: 16px 0 16px;
      max-width: 100%;
      white-space: wrap;
    `,
    Title: styled.p`
      font-size: 16px;
      font-family: Brown;
      line-height: 19px;
      color: #1f1f1f;
      margin: 4px 0 4px;
    `,
    Location: styled.p`
      font-size: 16px;
      font-family: Brown;
      color: #777e91;
      line-height: 19px;
      margin: 0;
    `,
  },
};

export default LayoutStyles;
