import { AnalyticsObjectType } from "../types/AnalyticsTypes";
import { customerTypes } from "../types/customerTypes";

const API_SOURCE = process.env.REACT_APP_API_SOURCE;
const API_ANALYTICS = process.env.REACT_APP_API_SOURCE;
const API_KEY = process.env.REACT_APP_API_KEY;

declare var xurealAPI: any;
declare var xrnalyticsAPI: any;

export const initXrnalyticsApi = () => {
  return new Promise((resolve, reject) => {
    try {
      xrnalyticsAPI.init(API_ANALYTICS, API_KEY);
      resolve("SUCCESS");
    } catch (e) {
      reject("ANALYTICS API ERROR");
    }
  });
};

export const initApiClient = () => {
  return new Promise((resolve, reject) => {
    try {
      xurealAPI.init(API_SOURCE, API_KEY);
      resolve("SUCCESS");
    } catch (e) {
      console.error(e);
      reject("ERROR");
    }
  });
};

export const getClientData = () => xurealAPI.getClientDataAll();

export const getConfigData = (configObj: {
  application_id: string;
  tenant_id: string;
}) => xurealAPI.getConfigData(configObj);

export const getPlayerData = (
  byValue: "email" | "username" | "xurealID" | "publicID",
  userData: string
) => xurealAPI.checkPlayerData(byValue, userData);

export const postCustomerData = (customerObj: customerTypes) =>
  xurealAPI.postCustomerData(customerObj);

export const getTagsByRating = (ratingObj: { rating: number }) =>
  xurealAPI.getTagsByRating(ratingObj);

export const ratePlayer = (reviewObj: {
  publicID: string;
  value: number;
  review: string;
  tags: string[];
  viewerID: string;
}) => xurealAPI.ratePlayer(reviewObj);

export const getViewerRating = (obj: { publicID: string; viewerID: string }) =>
  xurealAPI.getViewerRating(obj);

export const getClientProfileAbout = (obj: { client: string }) =>
  xurealAPI.getClientProfileAbout(obj);

export const getRatingTagByID = (obj: { ratingTagID: string }) =>
  xurealAPI.getRatingTagByID(obj);

export const getUserLocation = () => xurealAPI.getUserLocation();

export const getPublicPromotion = (obj: { promoID: string }) =>
  xurealAPI.getPublicPromotion(obj);

export const getProfileSkills = () => xurealAPI.getProfileSkills();

export const postMediaContent = (uploadData: { file: File; tag: string }) =>
  xurealAPI.postMediaContent(uploadData);

// Analyitcs
export const sendAnalytics = (analyticsObj: AnalyticsObjectType) =>
  xrnalyticsAPI.postXRnalytics(analyticsObj);

export const getAllProfileBanners = (dataObj: { clientID: string }) =>
  xurealAPI.getProfileHeaderBanners(dataObj);

// Share to wallet call

export const createWalletPass = (dataObj: {
  name: string;
  email: string;
  location: string;
  phone_number: string;
  qrcode: string;
  job_title: string;
  publicID: string;
  type: "ios" | "android";
}) => xurealAPI.createMobileWalletPass(dataObj);
