import React, { useEffect, useState } from "react";
import LayoutStyles from "../../App.styles";
import { useClient } from "../../context/ClientContext";
import { faCommentDots, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/pro-regular-svg-icons";
import { AnalyticsInputType } from "../../types/AnalyticsTypes";
import VCard from "vcard-creator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownToLine } from "@fortawesome/pro-solid-svg-icons";

const Communication = () => {
  const { Profile } = LayoutStyles;
  const { profileData, handleAppAnalytics } = useClient();
  const [compressedDataUrl, setCompressedDataUrl] = useState("");

  useEffect(() => {
    if (profileData) {
      if (profileData?.profile_image) {
        compressImage(profileData?.profile_image);
      }
    }
  }, [profileData]);

  const getBase64FromImageUrl = async (imageUrl: string) => {
    // Fetch the image as a binary blob
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    // Create a FileReader instance
    const fileReader = new FileReader();

    // Define a callback function to execute when the FileReader loads the image
    return new Promise((resolve, reject) => {
      fileReader.onload = () => {
        // Convert the binary blob to a base64-encoded string
        if (typeof fileReader.result === "string") {
          const base64String = fileReader?.result?.split(",")[1];
          resolve(base64String);
        }
      };

      // Start reading the image as a data URL
      fileReader.readAsDataURL(blob);
    });
  };

  const compressImage = async (imageUrl: string) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event?.target?.result as string;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const aspectRatio = img.width / img.height;
        canvas.width = aspectRatio * 400; // Set desired width (e.g. 200)
        canvas.height = 400; // Set desired height (e.g. 200)
        ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL("image/jpeg", 0.7);
        setCompressedDataUrl(dataUrl);
      };
    };
  };

  const saveContact = async () => {
    let image64 = (await getBase64FromImageUrl(compressedDataUrl)) as string;
    const myVCard = new VCard();
    myVCard
      .addPhoto(image64)
      .addName(profileData.name.split(" ")[1], profileData.name.split(" ")[0])
      .addJobtitle(
        process.env.REACT_APP_COMPANY_NAME + " " + profileData.job_title
      )
      .addEmail(profileData.email)
      .addPhoneNumber(profileData.phone, "WORK")
      .addURL(window.location.href)
      .addNote(profileData.bio)
      .addAddress(profileData.location)
      .addCompany(process.env.REACT_APP_COMPANY_NAME + " LLC");

    const element = document.createElement("a");
    const blob = new Blob([myVCard.toString()], {
      type: "text/x-vcard;charset=utf-8",
    });
    triggerCommunicationAnalytics("save-contact-click");
    element.href = URL.createObjectURL(blob);
    element.download = `${profileData.name}-${profileData.publicID}-contact.vcf`;
    element.type = "text/vcard";
    document.body.appendChild(element);
    //saveAs(blob, "contact.vcf");
    element.click();
  };

  const triggerCommunicationAnalytics = (
    event_action: string,
    eventAction?: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    // eventAction?.preventDefault();
    const event: AnalyticsInputType = {
      hotspot_id: profileData.publicID,
      content_type_id: "",
      event_category: "communication",
      event_action: event_action,
      label: "smart-profile",
      string_value: "",
      numeric_value: 0,
      completion_value: 0,
      duration: 0,
      spend_value: 0,
      name: profileData.email,
    };
    console.log(event);

    setTimeout(() => {
      handleAppAnalytics(event);
    }, 2000);

    // if (eventAction) {
    //   window.location.href = eventAction.currentTarget.getAttribute(
    //     "href"
    //   ) as string;
    // }
  };

  return (
    <Profile.SectionContainer>
      <Profile.Contact.Container>
        <Profile.Contact.Buttons
          href={`tel:${profileData?.phone}`}
          onClick={(event) => {
            event.preventDefault();
            triggerCommunicationAnalytics("phone-click", event);
            window.open(`tel:${profileData?.phone}`);
          }}
        >
          <Profile.Contact.ButtonIcon icon={faPhone} />
          Call
        </Profile.Contact.Buttons>
        <Profile.Contact.Buttons
          href={`sms:${profileData?.phone}`}
          onClick={(event) => triggerCommunicationAnalytics("sms-click", event)}
        >
          <Profile.Contact.ButtonIcon icon={faCommentDots} />
          Text
        </Profile.Contact.Buttons>
        <Profile.Contact.Buttons
          href={`mailto:${profileData?.email}`}
          onClick={(event) =>
            triggerCommunicationAnalytics("email-click", event)
          }
        >
          <Profile.Contact.ButtonIcon icon={faEnvelope} />
          Email
        </Profile.Contact.Buttons>
      </Profile.Contact.Container>
      <Profile.SaveContactButton onClick={saveContact}>
        <FontAwesomeIcon
          icon={faArrowDownToLine}
          color="#fff"
          style={{ height: 19 }}
        />
        Save contact
      </Profile.SaveContactButton>
    </Profile.SectionContainer>
  );
};

export default Communication;
