import React, { useEffect, useState } from "react";
import { getProfileSkills } from "../../actions";
import LayoutStyles from "../../App.styles";
import { useClient } from "../../context/ClientContext";

const AboutUser = () => {
  const { Profile } = LayoutStyles;
  const { profileData } = useClient();
  const [profileSkills, setProfileSkills] = useState<string[]>([]);
  const { initialized } = useClient();

  useEffect(() => {
    if (initialized && profileData?.skills)
      getProfileSkills().then(
        (res: {
          content: { skillID: string; text: string }[];
          result: string;
        }) => {
          if (res.result === "SUCCESS") {
            let arr = profileData?.skills
              .map((id) => {
                return res.content.find((skill) => skill.skillID === id);
              })
              .filter((skill) => skill !== undefined)
              .map((skill) => skill?.text);
            setProfileSkills(arr as string[]);
          }
        }
      );
  }, [initialized, profileData]);

  return (
    <>
      {profileData?.bio && (
        <Profile.Section.Title>ABOUT ME</Profile.Section.Title>
      )}
      <Profile.Section.Body>{profileData?.bio}</Profile.Section.Body>
      {profileData?.skills.length > 0 && (
        <Profile.Section.Title>SKILLS</Profile.Section.Title>
      )}
      {profileData?.skills.length > 0 && (
        <Profile.TagsContainer>
          {profileSkills.map((skill, index) => (
            <Profile.Tag key={index}>{skill}</Profile.Tag>
          ))}
        </Profile.TagsContainer>
      )}
    </>
  );
};

export default AboutUser;
