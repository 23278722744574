import { useClient } from "./context/ClientContext";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const DynamicMetaData: React.FC = () => {
  const { clientBranding } = useClient();
  const defaultMeta = {
    title: clientBranding?.web_title ?? "",
    description: clientBranding?.web_description ?? "",
    image: clientBranding?.logo ?? "",
    favicon: clientBranding?.logo ?? "",
  };

  const updateFavicon = (url: string) => {
    const favicon = document.querySelector(
      'link[rel="icon"]'
    ) as HTMLLinkElement;
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    if (clientBranding) updateFavicon(clientBranding.favicon);
  }, [clientBranding]);

  return (
    <Helmet>
      {/* <!-- Primary Meta Tags --> */}
      <title>{defaultMeta.title}</title>
      <meta name="title" content={defaultMeta.title} />
      <meta name="description" content={defaultMeta.description} />
      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      {/* <meta property="og:url" content="https://xureal.com/" /> */}
      <meta property="og:title" content={defaultMeta.title} />
      <meta property="o:description" content={defaultMeta.description} />
      <meta property="og:image" content={defaultMeta.image} />
      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      {/* <meta property="twitter:url" content="https://xureal.com/" /> */}
      <meta property="twitter:title" content={defaultMeta.title} />
      <meta property="twitter:description" content={defaultMeta.description} />
      <meta property="twitter:image" content={defaultMeta.image} />
    </Helmet>
  );
};

export default DynamicMetaData;

