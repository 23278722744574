import { useState, useRef, useEffect, useCallback } from "react";
import {
  getRatingTagByID,
  getTagsByRating,
  getViewerRating,
  ratePlayer,
} from "../../actions";
import LayoutStyles from "../../App.styles";
import { useClient } from "../../context/ClientContext";
import thankyouIcon from "../../assets/images/thankyou.png";
import { AnalyticsInputType } from "../../types/AnalyticsTypes";

type RatingTagTypes = {
  applicationID: string;
  hexColor: string;
  ratingTagID: string;
  text: string;
};
const RatingSection = () => {
  const { Profile } = LayoutStyles;
  const [rating, setRating] = useState(0);
  const [tags, setTags] = useState<RatingTagTypes[]>([]);
  const [selectedTags, setSelectedTags] = useState<RatingTagTypes[]>([]);
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(false);
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const { profileData, handleAppAnalytics, ipAddressHashed, } = useClient();
  const ref = useRef<HTMLInputElement>(null);

  const focusInput = useCallback(() => {
    if (ref) ref?.current?.focus();
  }, [ref.current]);

  const handleRating = (num: number) => {
    setRating(num);
    setSelectedTags([]);
    getTagsByRating({ rating: num }).then((res: any) => {
      console.log(res);
      setTags(res.content);
    });
  };

  const handleTagSelect = (tag: RatingTagTypes) => {
    if (
      selectedTags.find((element) => element.ratingTagID === tag.ratingTagID)
    ) {
      setSelectedTags((prev) => {
        const index = prev.findIndex(
          (element) => element.ratingTagID === tag.ratingTagID
        );
        if (index > -1) prev.splice(index, 1);
        return [...prev];
      });
    } else {
      setSelectedTags((prev) => [...prev, tag]);
    }
    console.log(selectedTags);
  };

  const triggerRatingAnalytics = () => {
    let reviewJSON = JSON.stringify({
      tags: selectedTags,
      review: review,
    });
    const event: AnalyticsInputType = {
      hotspot_id: profileData.publicID,
      content_type_id: "",
      event_category: "review",
      event_action: "review-submission",
      label: "smart-profile",
      string_value: reviewJSON,
      numeric_value: rating,
      completion_value: 0,
      duration: 0,
      spend_value: 0,
      name: profileData.email,
    };
    if (!profileData.isAdmin) handleAppAnalytics(event);
  };

  useEffect(() => {
    if (profileData?.publicID && ipAddressHashed) {
      console.log(profileData?.publicID);
      getViewerRating({
        publicID: profileData.publicID,
        viewerID: ipAddressHashed,
      })
        .then((res: { result: string; content: any }) => {
          if (res.result === "SUCCESS") {
            console.log(res.content);
            if (res.content.tags) {
              for (let ratingTagID of res.content.tags) {
                getRatingTagByID({ ratingTagID }).then(
                  (res: { result: string; content: RatingTagTypes }) => {
                    let tag = {
                      applicationID: res.content.applicationID,
                      hexColor: res.content.hexColor,
                      ratingTagID: res.content.ratingTagID,
                      text: res.content.text,
                    };
                    setSelectedTags((prev) => [...prev, tag]);
                  }
                );
              }
            }
            setRating(res.content.value as number);
            setReviewSubmitted(true);
          }
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [profileData, ipAddressHashed]);

  const submitReview = () => {
    let ratingObj = {
      publicID: profileData.publicID,
      value: rating,
      review: review,
      tags: selectedTags.map((tag) => tag.ratingTagID),
      viewerID: ipAddressHashed,
    };
    console.log(ratingObj);
    ratePlayer(ratingObj).then((res: any) => {
      console.log(res);
      if (res.result === "SUCCESS") {
        setReviewSubmitted(true);
        triggerRatingAnalytics();
      }
    });
  };

  if (loading) return <></>;

  if (reviewSubmitted && !loading) {
    return (
      <Profile.SectionContainer style={{ paddingTop: 56 }}>
        <img
          src={thankyouIcon}
          style={{ height: 49, margin: "0 auto 24px", display: "block" }}
          alt=""
        />
        <Profile.StatementText>
          Thank you for your feedback
        </Profile.StatementText>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: 122,
            margin: "0 auto 12px",
          }}
        >
          {Array(5)
            .fill("")
            .map((_, index) =>
              rating <= index ? (
                <Profile.Rating.FilledStar
                  key={index}
                  color="#D8D8D8"
                  style={{ height: 20, width: 20 }}
                />
              ) : (
                <Profile.Rating.FilledStar
                  key={index}
                  style={{ height: 20, width: 20 }}
                  color="#006fb8"
                />
              )
            )}
        </div>
        <Profile.TagsContainer>
          {selectedTags.map((tag, index) => (
            <Profile.Tag key={index}>{tag.text}</Profile.Tag>
          ))}
        </Profile.TagsContainer>
      </Profile.SectionContainer>
    );
  }

  return (
    <Profile.SectionContainer style={{ paddingTop: 56 }}>
      <Profile.Section.Title style={{ marginBottom: 0 }}>
        FEEDBACK
      </Profile.Section.Title>
      <Profile.Feedback>Let me know what you think!</Profile.Feedback>
      <Profile.Rating.Container>
        {Array(5)
          .fill("")
          .map((_, index) =>
            rating <= index ? (
              <Profile.Rating.EmptyStar
                onClick={() => handleRating(index + 1)}
                key={index}
              />
            ) : (
              <Profile.Rating.FilledStar
                onClick={() => handleRating(index + 1)}
                color="#006fb8"
                key={index}
              />
            )
          )}
      </Profile.Rating.Container>
      {tags.length === 0 && (
        <Profile.Section.Title
          style={{ marginBottom: 0, color: "#006FB8", fontSize: 16 }}
        >
          Write a review
        </Profile.Section.Title>
      )}
      <Profile.TagsContainer>
        {tags.map((tag, index) => (
          <Profile.RatingTag
            onClick={() => handleTagSelect(tag)}
            $selected={selectedTags.includes(tag)}
            key={index}
          >
            {tag.text}
          </Profile.RatingTag>
        ))}
      </Profile.TagsContainer>
      {rating > 0 && (
        <>
          <Profile.Rating.ReviewContainer onClick={() => focusInput()}>
            <Profile.Rating.ReviewInput
              multiline
              inputRef={ref}
              onChange={(e) => setReview(e.currentTarget.value)}
            ></Profile.Rating.ReviewInput>
          </Profile.Rating.ReviewContainer>
          <Profile.Rating.SubmitButton onClick={submitReview}>
            Submit Feedback
          </Profile.Rating.SubmitButton>
        </>
      )}
    </Profile.SectionContainer>
  );
};

export default RatingSection;
