import { faPlayCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useEffect } from "react";
import LayoutStyles from "../../App.styles";
import ReactPlayer from "react-player";
import { useClient } from "../../context/ClientContext";
import { AnalyticsInputType } from "../../types/AnalyticsTypes";

const AboutSection = () => {
  const { profileData, handleAppAnalytics, clientAboutData } = useClient();
  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    console.log('about data', clientAboutData)
  }, [clientAboutData])

  const triggerAboutAnalytics = () => {
    const event: AnalyticsInputType = {
      hotspot_id: profileData.publicID,
      content_type_id: clientAboutData.video.videoID,
      event_category: "profile-media",
      event_action: "video-click",
      label: "smart-profile",
      string_value: clientAboutData.video.title,
      numeric_value: 0,
      completion_value: 0,
      duration: 0,
      spend_value: 0,
      name: clientAboutData.video.title,
    };
    handleAppAnalytics(event);
  };

  const handleVideoClick = () => {
    if (playerRef.current) {
      let internalPlayer = playerRef.current.getInternalPlayer();
      if (internalPlayer) {
        internalPlayer.play()
      }
    }
  }

  return (
    <>
      {clientAboutData?.video?.url && (
        <LayoutStyles.Profile.PlayerWrapper>
            <ReactPlayer
              ref={playerRef}
              width={"100%"}
              height={"100%"}
              onStart={triggerAboutAnalytics}
              url={clientAboutData?.video?.url}
              controls
              style={{ position: "absolute", top: "0", left: "0", overflow: 'hidden' }}
              playIcon={
                <div style={{ position: "absolute" }}>
                  <FontAwesomeIcon
                    icon={faPlayCircle}
                    color="black"
                    style={{ height: 35, opacity: 0.5 }}
                  />
                </div>
              }
            />
        </LayoutStyles.Profile.PlayerWrapper>
      )}

      {clientAboutData?.image?.url && (
        <img
          src={clientAboutData?.image?.url}
          alt=""
          style={{ width: "100%", marginBottom: '60px' }}
        />
      )}
    </>
  );
};

export default AboutSection;
