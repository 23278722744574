import { faBars, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutStyles from "../../App.styles";
import logo from "../../assets/images/adt-logo.png";
import { useClient } from "../../context/ClientContext";

const Header = () => {
  const { clientBranding } = useClient();
  const isIos = localStorage.getItem("ios");

  // const handleiOSDeepLink = () => {
  //   console.log("Handling deep link to ADT iOS app");
  //   window.location.href = process.env.REACT_APP_PREVIEW_RETURN as string;
  // };

  const handleIosClose = () => {
    let returnUrl = localStorage.getItem('preview_return');
    // let armode = param.get("armode");

    // if (armode == "true") {
    //   handleiOSDeepLink();
    //   return;
    // }
    //xureal360MSG.onEventClose();
    console.log('return url', returnUrl ? returnUrl : process.env.REACT_APP_PREVIEW_RETURN)
    window.location.href = returnUrl ? returnUrl : process.env.REACT_APP_PREVIEW_RETURN as string;
  };
  return (
    <LayoutStyles.Header $center={isIos}>
      {isIos && (
        <div
          style={{ fontWeight: 600, cursor: "pointer" }}
          onClick={() => handleIosClose()}
        >
          <FontAwesomeIcon
            style={{ marginRight: "7px" }}
            icon={faChevronLeft}
          />
          Back
        </div>
      )}
      <img src={clientBranding?.logo ?? ""} height={43.02} alt="" />
      {/* <FontAwesomeIcon icon={faBars} color={"#016FB7"} style={{ height: 23 }} /> */}
      {isIos && <div></div>}
    </LayoutStyles.Header>
  );
};

export default Header;
