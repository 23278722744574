import React from "react";
import AdBannerImg from "../assets/images/AdBanner.png";
import LayoutStyles from "../App.styles";
import { useClient } from "../context/ClientContext";
import qrIcon from "../assets/images/qr.png";

import RatingSection from "../components/RatingSection";
import { useEffect, useState } from "react";
import AboutUser from "../components/AboutUser/AboutUser";
import Communication from "../components/Communication";
import AboutClient from "../components/AboutClient";
import Promotion from "../components/Promotion";
import QRModal from "../components/QRModal/QRModal";

const Profile = () => {
  const { profileData, coverArray, usesCoverImgs, promoData } = useClient();
  const { Profile, Divider } = LayoutStyles;
  const [coverArtIndex, setCoverArtIndex] = useState(0);
  const [qrOpen, setqrOpen] = useState(false);
  const profileLink = process.env.REACT_APP_ADT_SMART_PROFILE as string;
  useEffect(() => {
    if (profileData) {
      if (profileData?.banner_image) {
        setCoverArtIndex(profileData.banner_image);
      } else {
        setCoverArtIndex(0);
      }
    }
  }, [profileData]);

  useEffect(() => {
    console.log("coverArray", coverArray);
    console.log(usesCoverImgs);
    console.log("promo", promoData);
  }, [promoData]);

  return (
    <>
      <div>
        <Profile.CoverArt
          $background={`url(${coverArray[coverArtIndex].image})`}
        ></Profile.CoverArt>
      </div>
      <Profile.InfoContainer>
        <Profile.Pfp $pfp={profileData?.profile_image}>
          <Profile.QrIconSection onClick={() => setqrOpen(true)}>
            <img src={qrIcon} style={{ height: 22 }} alt="" />
          </Profile.QrIconSection>
        </Profile.Pfp>
        <Profile.Name>{profileData?.name}</Profile.Name>
        {profileData?.job_title && profileData?.job_title !== "N/A" && (
          <Profile.JobTitle>{profileData?.job_title}</Profile.JobTitle>
        )}
        {profileData?.location && profileData?.location !== "N/A" && (
          <Profile.Location>{profileData?.location + ""}</Profile.Location>
        )}
        <Communication />
        <Divider />
        <AboutUser />
        <AboutClient />
        {!promoData?.hasExpiration ? (
          <Promotion />
        ) : (
          !(
            new Date(promoData?.endDate) < new Date() ||
            new Date() < new Date(promoData?.startDate)
          ) && <Promotion />
        )}
        <RatingSection />
      </Profile.InfoContainer>
      {qrOpen && (
        <QRModal
          name={profileData.name}
          location={profileData.location}
          title={profileData.job_title}
          urlLink={`${profileLink}?id=${profileData.publicID}`}
          closeModal={setqrOpen}
          pfp={profileData.profile_image}
        />
      )}
    </>
  );
};

export default Profile;
