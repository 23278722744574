import React from "react";
import LayoutStyles from "../../App.styles";
import { useClient } from "../../context/ClientContext";
import { AnalyticsInputType } from "../../types/AnalyticsTypes";

const Promotion = () => {
  const { promoData, profileData, handleAppAnalytics } = useClient();
  const { Profile } = LayoutStyles;

  const triggerPromoAnalytics = () => {
    console.log(promoData);
    const event: AnalyticsInputType = {
      hotspot_id: profileData.publicID,
      content_type_id: promoData.promo_id,
      event_category: "profile-promo",
      event_action: "promo-click",
      label: "smart-profile",
      string_value: promoData.promo_id,
      numeric_value: 0,
      completion_value: 0,
      duration: 0,
      spend_value: 0,
      name: promoData.header,
    };
    handleAppAnalytics(event);
  };

  const openPromo = () => {
    // if (promoData?.cta) {
    triggerPromoAnalytics();
    window.open(promoData.cta, "_blank");
    //  }
  };

  return (
    <div onClick={openPromo} style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Profile.PromoImage src={promoData?.image} />
      {promoData?.header && (
        <Profile.PromoText.Container>
          <Profile.PromoText.Header>
            {promoData?.header}
          </Profile.PromoText.Header>
          <Profile.PromoText.Subtext>
            {promoData?.subtext}
          </Profile.PromoText.Subtext>
          <Profile.PromoText.Disclaimer>
            {promoData?.disclaimer}
          </Profile.PromoText.Disclaimer>
        </Profile.PromoText.Container>
      )}
    </div>
  );
};

export default Promotion;
