import LayoutStyles from "../../App.styles";
import logo from "../../assets/images/adt-logo.png";
import { useClient } from "../../context/ClientContext";

const Header = () => {
  const { clientBranding, clientName } = useClient();

  const formatName = (str: string) => {
    let capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1);
    if (capitalizedStr === 'Adt-client') {
      capitalizedStr = 'ADT'
    }
    return capitalizedStr;
  };

  return (
    <LayoutStyles.Footer>
      <img
        src={clientBranding?.logo ?? ""}
        height={55.03}
        style={{ marginBottom: 19.97 }}
        alt=""
      />
      © 2024 Elevux. All rights reserved.
    </LayoutStyles.Footer>
  );
};

export default Header;
